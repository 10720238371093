import React, { FormEvent, useState } from 'react'
import { CustomModal } from '../shared/CustomModal'
import { Form, FormControl, FormGroup, FormLabel, FormSelect } from 'react-bootstrap'
import { PaymentMethods } from '../mocks/constants'
import { ApiClient } from '../services/api/ApiClient'
import { PaymentInvoice } from '../pdf/Receipt'
type Props = {
  patient: any
  onClose: () => void
}
export const Payment: React.FC<Props> = ({ onClose, patient }) => {
  const [formData, setFormData] = useState<any>({ patient_id: patient.id })
  const [receiptData, setReceiptData] = useState<any>(null)
  const handleSubmit = (e: FormEvent) => {

    e.preventDefault()
    if (receiptData) return
    ApiClient.payments.create(formData).then(res => {

      setReceiptData(res)
    })

  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };


  return (
    <CustomModal onClose={onClose} title={"Nuevo Pago"}>

      <Form onSubmit={handleSubmit} id='new-form'>

        <FormGroup>
          <FormLabel>Paciente:</FormLabel>
          <FormControl
            disabled
            size='sm'
            className='rounded-0'
            type='text'
            name='amount'
            value={patient.name}

          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Metodo de Pago:</FormLabel>
          <FormSelect
            size='sm'
            className='rounded-0'

            name='first_name'
            value={formData?.payment_method}
            onChange={({ target }) => setFormData({ ...formData, payment_method: target.value })}
          >
            <option value="">Seleccione...</option>
            {PaymentMethods.map((method) => (
              <option value={method}>{method}</option>
            ))}
          </FormSelect>
        </FormGroup>
        <FormGroup>
          <FormLabel>Monto:</FormLabel>
          <FormControl
            size='sm'
            className='rounded-0'
            type='number'
            name='amount'
            value={formData?.amount}
            onChange={handleChange}
          />
        </FormGroup>
      </Form>
      <div style={{ height: 400 }}>
        {receiptData && (<PaymentInvoice patient={patient} data={receiptData} />)}
      </div>
    </CustomModal>
  )
}